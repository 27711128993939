<template>
  <el-dialog
    top="18vh"
    width="400px"
    visible
    append-to-body
    custom-class="rounded template-name-modal"
    :show-close="false"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4">
        <div>
          <div class="d-flex align-items-center">
            <DeleteWarningIcon :size="24" class="ms-2" />
            <h2>{{ $t('automation.templateManagement.archiveModal.title') }}</h2>
          </div>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="row ps-4 pe-4">
      <div :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
        <p class="text-typography-primary no-word-break">
          {{ $t('automation.templateManagement.archiveModal.body', { templateName }) }}
        </p>
      </div>
      <div class="col"></div>
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" class="modal-btn" @click="$emit('close')">{{
          $t('automation.templateManagement.archiveModal.cancel')
        }}</Button>
        <Button class="modal-btn danger-btn" @click="$emit('approve')">{{
          $t('automation.templateManagement.archiveModal.approve')
        }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon, DeleteWarningIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon, DeleteWarningIcon },
  props: {
    templateName: { type: String, default: '-' },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .template-name-modal {
  .el-dialog__headerbtn {
    top: 1em;
  }
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }

  .radio-column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap as needed */
  }
}

.no-word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.modal-btn {
  padding: 13px;
}

.danger-btn {
  background-color: $checkbox-error;
}
</style>
