<template>
  <el-dialog
    top="30vh"
    width="520px"
    visible
    append-to-body
    custom-class="rounded template-name-modal"
    :show-close="false"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4">
        <div>
          <h2>{{ $t('automation.templateManagement.createModal.title') }}</h2>
          <p>{{ $t(`document.exports.schema.type.shortName.${documentType}`) }} ‧ {{ supplier.name }}</p>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="row p-4">
      <div class="pt-2" :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
        <p class="text-typography-primary">{{ $t('automation.templateManagement.createModal.label') }}</p>
      </div>
      <div class="col">
        <el-form ref="templateNameForm" :model="templateNameForm" @submit.native.prevent="submitForm()">
          <el-form-item prop="name" :rules="{ required: true }">
            <el-input v-model="templateNameForm.name" />
          </el-form-item>
          <el-form-item
            prop="isManual"
            :rules="[
              {
                required: true,
                message: $t('automation.templateManagement.createModal.isManualSelectionRadioGroup.errorMessage'),
              },
            ]"
          >
            <p class="text-typography-primary">
              {{ $t('automation.templateManagement.createModal.isManualSelectionRadioGroup.title') }}
            </p>
            <el-radio-group v-model="templateNameForm.isManual" class="radio-column" :disabled="areOptionsDisabled">
              <el-radio label="digital" size="large">{{
                $t('automation.templateManagement.createModal.isManualSelectionRadioGroup.digital')
              }}</el-radio>
              <el-radio label="manual" size="large">{{
                $t('automation.templateManagement.createModal.isManualSelectionRadioGroup.manual')
              }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            prop="locale"
            :rules="[
              {
                required: true,
                message: $t('automation.templateManagement.createModal.locale.errorMessage'),
              },
            ]"
          >
            <p class="text-typography-primary">
              {{ $t('automation.templateManagement.createModal.locale.title') }}
            </p>
            <el-select
              v-model="templateNameForm.locale"
              :disabled="areOptionsDisabled"
              :placeholder="$t('automation.templateManagement.createModal.locale.placeholder')"
            >
              <el-option
                v-for="item in localeOptions"
                :key="item"
                :label="$t(`automation.templateManagement.createModal.locale.${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button @click="submitForm">{{ $t('commons.apply') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon },
  props: {
    templateName: { type: String, default: null },
    isManual: { type: String, default: null },
    locale: { type: String, default: null },
    documentType: { type: String, required: true },
    supplier: { type: Object, required: true },
  },
  data() {
    return {
      templateNameForm: { name: this.templateName, isManual: this.isManual, locale: this.locale },
      localeOptions: ['en-US', 'he-IL'],
    };
  },
  computed: {
    areOptionsDisabled() {
      if (this.templateName || this.isManual || this.locale) return true;
      return false;
    },
  },
  methods: {
    submitForm() {
      this.$refs.templateNameForm.validate((valid) => {
        if (valid) {
          this.$emit('submit', {
            name: this.templateNameForm.name,
            isManual: this.templateNameForm.isManual === 'manual' ? true : false,
            locale: this.templateNameForm.locale,
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .template-name-modal {
  .el-dialog__headerbtn {
    top: 1em;
  }
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }
  .el-dialog__body {
    border-top: 1.5px solid $outline;
  }
  .radio-column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap as needed */
  }
}
</style>
