<template>
  <div class="d-flex align-items-center border rounded p-2 px-4">
    <div class="d-flex gap-7">
      <el-radio-group v-model="selectedMode" class="d-flex flex-row-reverse">
        <el-radio-button :disabled="!templates.length" :label="STATE_MODE.AUTOMATION" class="plain-button">
          {{ $t('automation.templateManagement.menuBar.mode.automation') }}
        </el-radio-button>
        <el-radio-button :label="STATE_MODE.TEMPLATE_EDITING" class="plain-button">
          {{ $t('automation.templateManagement.menuBar.mode.edit') }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="d-flex flex-grow-1 justify-content-end align-items-center">
      <template v-if="selectedMode === STATE_MODE.TEMPLATE_EDITING">
        <p class="fw-bold mx-1">
          {{ $t('automation.templateManagement.menuBar.templateLabel') }}
        </p>
        <el-select
          :value="templateId"
          :placeholder="$t('automation.templateManagement.menuBar.noTemplate')"
          class="dropdown-size dropdown-text-color templates-select"
          :class="`number-of-prefix-icons-${numberOfPrefixIcons}`"
          size="small"
          @input="handleTemplateSelection"
        >
          <template v-if="isSelectedTemplateArchived || isTemplateSelectedManual" #prefix>
            <div class="dropdown-prefix-container center-select-icon">
              <div v-if="isSelectedTemplateArchived">
                <ArchiveIcon class="manual-document-icon" width="18" />
              </div>
              <div v-if="isTemplateSelectedManual">
                <PencilTrailIcon class="manual-document-icon" width="18" />
              </div>
            </div>
          </template>

          <el-option
            v-for="templateOption in templates"
            :key="templateOption.id"
            :label="templateOption.name"
            :value="templateOption.id"
          >
            <div class="row" :class="templateId !== templateOption.id ? 'text-typography-primary' : 'text-primary'">
              <div v-if="templateOption.isArchived" class="col-1 g-0" style="margin-right: 6px">
                <ArchiveIcon width="18" />
              </div>
              <div v-if="templateOption.isManual" class="col-1 g-0" style="margin-right: 6px">
                <PencilTrailIcon width="18" height="18" />
              </div>
              <div class="col g-0" style="margin-right: 6px; overflow: hidden">
                <p class="col fw-normal">
                  {{ templateOption.name }}
                </p>
              </div>
            </div>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="isSelectedTemplateArchived"
          :disabled="!isEditable || isDirty"
          :content="$t('automation.automationManagement.general.takeOutOfArchive')"
          placement="top"
        >
          <Button :disabled="!isEditable || isDirty" type="icon" class="archive-btn" @click="$emit('un-archive')">
            <UnarchiveIcon />
          </Button>
        </el-tooltip>

        <el-tooltip
          v-if="!isSelectedTemplateArchived"
          :disabled="!isEditable || isDirty"
          :content="$t('automation.automationManagement.general.markArchive')"
          placement="top"
        >
          <Button :disabled="!isEditable || isDirty" type="icon" class="archive-btn" @click="$emit('archive')">
            <ArchiveIcon />
          </Button>
        </el-tooltip>

        <Button :disabled="!isEditable || isDirty" class="m-0" type="icon" @click="$emit('edit')">
          <EditIcon />
        </Button>

        <div class="vr mx-4"></div>
        <Button :disabled="!templateId" type="icon" class="p-1" @click="$emit('structure-params')">
          <ParametersIcon />
          <span class="d-inline-flex p-1">
            {{ $t(`automation.templateManagement.menuBar.structureParams.${isEditable ? 'edit' : 'show'}`) }}
          </span>
        </Button>

        <div class="vr mx-4"></div>
        <Button :disabled="!noOriginal" type="icon" class="p-1" @click="$emit('create')">
          <TemplateIcon />
          <span class="d-inline-flex p-1">{{ $t('automation.templateManagement.menuBar.newTemplate') }}</span>
        </Button>
      </template>
      <Button v-if="selectedMode === STATE_MODE.AUTOMATION" class="p-1" @click="$emit('run-automation')">
        <PlayIcon />
        <span :class="{ ['ps-2']: $direction === 'rtl', ['pe-2']: $direction === 'ltr' }">
          {{ $t('automation.templateManagement.menuBar.runAutomation') }}
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import { Button } from '@/modules/core';
import {
  EditIcon,
  PencilTrailIcon,
  TemplateIcon,
  ParametersIcon,
  PlayIcon,
  ArchiveIcon,
  UnarchiveIcon,
} from '@/assets/icons';

import { STATE_MODE } from '../../../constants';

export default {
  components: {
    Button,
    EditIcon,
    PencilTrailIcon,
    TemplateIcon,
    ParametersIcon,
    PlayIcon,
    ArchiveIcon,
    UnarchiveIcon,
  },
  props: {
    mode: { type: String, default: null },
    templateId: { type: String, default: null },
    templates: {
      type: Array,
      default: () => [],
    },
    noOriginal: { type: Boolean, default: false },
    isOriginal: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
    isDirty: { type: Boolean, default: false },
  },
  data() {
    return {
      STATE_MODE,
    };
  },
  computed: {
    isTemplateSelectedManual() {
      const selectedTemplate = this.templates.find(({ id }) => id === this.templateId);
      if (selectedTemplate) return selectedTemplate.isManual;
      return false;
    },

    isSelectedTemplateArchived() {
      const selectedTemplate = this.templates.find(({ id }) => id === this.templateId);
      if (selectedTemplate) return selectedTemplate.isArchived;
      return false;
    },

    numberOfPrefixIcons() {
      let num = 0;
      if (this.isTemplateSelectedManual) {
        num++;
      }
      if (this.isSelectedTemplateArchived) {
        num++;
      }
      return num;
    },

    selectedMode: {
      set(mode) {
        this.$emit('update:mode', mode);
      },
      get() {
        return this.mode;
      },
    },
  },
  methods: {
    handleTemplateSelection(newId) {
      if (this.isDirty) {
        this.$confirm(
          this.$t('automation.templateManagement.menuBar.unsavedWarring.message'),
          this.$t('automation.templateManagement.menuBar.unsavedWarring.title'),
          {
            confirmButtonText: this.$t('commons.apply'),
            cancelButtonText: this.$t('commons.cancel'),
            type: 'warning',
          }
        )
          .then(() => {
            this.$emit('update:templateId', newId);
          })
          .catch();
      } else this.$emit('update:templateId', newId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.divider {
  width: 1px;
  background: $outline;
}

::v-deep .templates-select {
  &.number-of-prefix-icons-1 {
    .el-input--prefix .el-input__inner {
      padding-right: 30x;
    }
  }
  &.number-of-prefix-icons-2 {
    .el-input--prefix .el-input__inner {
      padding-right: 55px;
    }
  }
}

.dropdown-prefix-container {
  display: flex;
  align-items: center;
}

.dropdown-size {
  width: 240px;
}

.dropdown-text-color :deep(.el-input__inner) {
  color: $typography-primary;
}

.center-select-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $typography-primary;
}

.manual-document-icon {
  margin-right: 5px;
}

.archive-btn {
  margin-right: 5px;
}

::v-deep .plain-button {
  .el-radio-button__inner {
    background: $white;
    color: $typography-secondary;
    border-color: $outline;
  }

  &.is-active .el-radio-button__inner {
    background: change-color($color: $primary, $lightness: 97.8%);
    color: $primary;
    border-color: $primary;
  }
}
</style>
