var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"top":"30vh","width":"520px","visible":"","append-to-body":"","custom-class":"rounded template-name-modal","show-close":false},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between p-4"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('automation.templateManagement.createModal.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t(`document.exports.schema.type.shortName.${_vm.documentType}`))+" ‧ "+_vm._s(_vm.supplier.name))])]),_c('Button',{staticClass:"p-0",attrs:{"type":"icon"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('CloseIcon')],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"p-4"},[_c('Button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('commons.cancel')))]),_c('Button',{on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t('commons.apply')))])],1)]},proxy:true}])},[_c('div',{staticClass:"row p-4"},[_c('div',{staticClass:"pt-2",class:_vm.$direction === 'rtl' ? 'pe-4' : 'ps-4'},[_c('p',{staticClass:"text-typography-primary"},[_vm._v(_vm._s(_vm.$t('automation.templateManagement.createModal.label')))])]),_c('div',{staticClass:"col"},[_c('el-form',{ref:"templateNameForm",attrs:{"model":_vm.templateNameForm},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('el-form-item',{attrs:{"prop":"name","rules":{ required: true }}},[_c('el-input',{model:{value:(_vm.templateNameForm.name),callback:function ($$v) {_vm.$set(_vm.templateNameForm, "name", $$v)},expression:"templateNameForm.name"}})],1),_c('el-form-item',{attrs:{"prop":"isManual","rules":[
            {
              required: true,
              message: _vm.$t('automation.templateManagement.createModal.isManualSelectionRadioGroup.errorMessage'),
            },
          ]}},[_c('p',{staticClass:"text-typography-primary"},[_vm._v(" "+_vm._s(_vm.$t('automation.templateManagement.createModal.isManualSelectionRadioGroup.title'))+" ")]),_c('el-radio-group',{staticClass:"radio-column",attrs:{"disabled":_vm.areOptionsDisabled},model:{value:(_vm.templateNameForm.isManual),callback:function ($$v) {_vm.$set(_vm.templateNameForm, "isManual", $$v)},expression:"templateNameForm.isManual"}},[_c('el-radio',{attrs:{"label":"digital","size":"large"}},[_vm._v(_vm._s(_vm.$t('automation.templateManagement.createModal.isManualSelectionRadioGroup.digital')))]),_c('el-radio',{attrs:{"label":"manual","size":"large"}},[_vm._v(_vm._s(_vm.$t('automation.templateManagement.createModal.isManualSelectionRadioGroup.manual')))])],1)],1),_c('el-form-item',{attrs:{"prop":"locale","rules":[
            {
              required: true,
              message: _vm.$t('automation.templateManagement.createModal.locale.errorMessage'),
            },
          ]}},[_c('p',{staticClass:"text-typography-primary"},[_vm._v(" "+_vm._s(_vm.$t('automation.templateManagement.createModal.locale.title'))+" ")]),_c('el-select',{attrs:{"disabled":_vm.areOptionsDisabled,"placeholder":_vm.$t('automation.templateManagement.createModal.locale.placeholder')},model:{value:(_vm.templateNameForm.locale),callback:function ($$v) {_vm.$set(_vm.templateNameForm, "locale", $$v)},expression:"templateNameForm.locale"}},_vm._l((_vm.localeOptions),function(item){return _c('el-option',{key:item,attrs:{"label":_vm.$t(`automation.templateManagement.createModal.locale.${item}`),"value":item}})}),1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }